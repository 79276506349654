import { CaptionLabelProps, DayPicker } from "react-day-picker";
import es from "date-fns/locale/es";

interface MonthPickerProps {
	monthIndex: number;
	selectedDays: Date[];
	setSelectedDays: (days: Date[]) => void;
	isLaboralDay: () => boolean;
	isAdmin: boolean;
	dayOffModifier: Date[];
}

const actualYear = new Date().getFullYear();

const DayOffModifierStyle = {
	backgroundColor: "#B78DB7",
	color: "white",
};

const DayModifierStyle = {
	backgroundColor: "#62098C",
	color: "white",
};

const MonthPicker: React.FC<MonthPickerProps> = ({
	monthIndex,
	selectedDays,
	setSelectedDays,
	isLaboralDay,
	isAdmin,
	dayOffModifier,
}) => (
	<DayPicker
		mode="multiple"
		locale={es}
		className={`${
			isAdmin ? (isLaboralDay() ? "laboral-day" : "no-laboral-day") : "disable-select"
		}`}
		defaultMonth={new Date(actualYear, monthIndex)}
		month={new Date(actualYear, monthIndex)}
		selected={selectedDays}
		onSelect={isAdmin ? (days) => setSelectedDays(days ?? []) : undefined}
		disableNavigation
		modifiers={{
			dayOffModifier: dayOffModifier,
		}}
		modifiersStyles={{
			dayOffModifier: isLaboralDay() ? DayOffModifierStyle : DayModifierStyle,
		}}
		components={{
			CaptionLabel: (props: CaptionLabelProps) => (
				<span
					className="rdp-caption_label"
					{...props}
				>
					{props.displayMonth.toLocaleDateString("es-ES", { month: "long" })}
				</span>
			),
		}}
	/>
);

export default MonthPicker;
