import {
	Modal,
	Box,
	Autocomplete,
	TextField,
	ToggleButton,
	ToggleButtonGroup,
	CircularProgress,
	Backdrop,
} from "@mui/material";
import { PlanificationContext } from "../../views/planificationView/planificationView";
import { ReactComponent as ActualizarIcon } from "../../assets/icons/guardar.svg";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { ReactComponent as ExcelIcon } from "../../assets/icons/excel.svg";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import UploadArchive from "../uploadArchive/uploadArchive";
import { URI_API, getHeaders } from "../../utils/config";
import { ToastContainer, toast } from "react-toastify";
import "react-day-picker/dist/style.css";
import "./planificationCalendar.scss";
import { isNurseAdmin } from "../../store/interfaces";
import { useSelector } from "react-redux";
import { AppState } from "../../store/reducer";
import MonthPicker from "./MonthPicker";

const ClassX = [
	"AUX A",
	"AUX B",
	"AUX C",
	"COORD",
	"ENF A",
	"ENF B",
	"ENF C",
	"ENF D",
	"ENF ESP",
	"ENF SUB",
	"JEFE ENF",
];
export default function PlanificationCalendar() {
	const { role, availableNurses } = useContext(PlanificationContext);
	const [showModal, setShowModal] = useState(false);
	const [actualYear, setActualYear] = useState(new Date().getFullYear());
	const [januaryMonth, setJanuaryMonth] = useState<any>([]);
	const [februaryMonth, setFebruaryMonth] = useState<any>([]);
	const [marchMonth, setMarchMonth] = useState<any>([]);
	const [aprilMonth, setAprilMonth] = useState<any>([]);
	const [mayMonth, setMayMonth] = useState<any>([]);
	const [juneMonth, setJuneMonth] = useState<any>([]);
	const [julyMonth, setJulyMonth] = useState<any>([]);
	const [augustMonth, setAugustMonth] = useState<any>([]);
	const [septemberMonth, setSeptemberMonth] = useState<any>([]);
	const [octoberMonth, setOctoberMonth] = useState<any>([]);
	const [novemberMonth, setNovemberMonth] = useState<any>([]);
	const [decemberMonth, setDecemberMonth] = useState<any>([]);
	const [januaryMonthOff, setJanuaryMonthOff] = useState<any>([]);
	const [februaryMonthOff, setFebruaryMonthOff] = useState<any>([]);
	const [marchMonthOff, setMarchMonthOff] = useState<any>([]);
	const [aprilMonthOff, setAprilMonthOff] = useState<any>([]);
	const [mayMonthOff, setMayMonthOff] = useState<any>([]);
	const [juneMonthOff, setJuneMonthOff] = useState<any>([]);
	const [julyMonthOff, setJulyMonthOff] = useState<any>([]);
	const [augustMonthOff, setAugustMonthOff] = useState<any>([]);
	const [septemberMonthOff, setSeptemberMonthOff] = useState<any>([]);
	const [octoberMonthOff, setOctoberMonthOff] = useState<any>([]);
	const [novemberMonthOff, setNovemberMonthOff] = useState<any>([]);
	const [decemberMonthOff, setDecemberMonthOff] = useState<any>([]);
	const [nurseNomina, setNurseNomina] = useState<any>("");
	const [selectedNurse, setSelectedNurse] = useState<any>({});
	const [, updateState] = useState<any>();
	const [isUpdating, setIsUpdating] = useState(false);
	const [nurseClassxSelected, setNurseClassxSelected] = useState({
		class: "",
	});
	const [newClassxSelected, setNewClassxSelected] = useState("");
	const [nursesByHospital, setNursesByHospital] = useState([]);

	const [loadingAutocompleteSelectNurse, setLoadingAutocompleteSelectNurse] =
		useState<boolean>(false);
	const [showBackdrop, setShowBackdrop] = useState<boolean>(false);

	const isAdmin: boolean = isNurseAdmin(
		useSelector((state: AppState) => state.loggedUser).position,
	);

	/**
	 * Sends the changes made to the nurse's schedule.
	 */
	const sendChanges = () => {
		setIsUpdating(true);

		const mergeAndFormatDates = (months: Date[][]): string[] => {
			return months.flat().map((day) => day.toISOString().split("T")[0]);
		};

		const changedDays = mergeAndFormatDates([
			januaryMonth,
			februaryMonth,
			marchMonth,
			aprilMonth,
			mayMonth,
			juneMonth,
			julyMonth,
			augustMonth,
			septemberMonth,
			octoberMonth,
			novemberMonth,
			decemberMonth,
		]);

		const changedDaysOffs = mergeAndFormatDates([
			januaryMonthOff,
			februaryMonthOff,
			marchMonthOff,
			aprilMonthOff,
			mayMonthOff,
			juneMonthOff,
			julyMonthOff,
			augustMonthOff,
			septemberMonthOff,
			octoberMonthOff,
			novemberMonthOff,
			decemberMonthOff,
		]);

		const payload = {
			days: changedDays,
			nomina: nurseNomina,
			work_schedule_id: selectedNurse.work_schedule_id,
			nurse_id: selectedNurse.nurse_id,
			classx: newClassxSelected,
			days_offs: changedDaysOffs,
		};

		const saveChanges = async () => {
			try {
				const response = await fetch(
					`${URI_API}/nurse/save_changes`,
					getHeaders("POST", payload),
				);
				const data = await response.json();
				toast("Actualizado", { type: "success", containerId: "PC" });
			} catch (error) {
				toast("Error al actualizar.", {
					type: "error",
					containerId: "PC",
				});
			} finally {
				setIsUpdating(false);
			}
		};

		saveChanges();
	};

	const getNurses = async () => {
		setLoadingAutocompleteSelectNurse(true);
		try {
			const response = await fetch(`${URI_API}/getNursesByHospital`, getHeaders());
			const data = await response.json();
			setNursesByHospital(data);
		} catch (error) {
			console.error("Error fetching nurses:", error);
		} finally {
			setLoadingAutocompleteSelectNurse(false);
		}
	};

	useEffect(() => {
		getNurses();
	}, []);

	useEffect(() => {
		setShowModal(role !== "");
	}, [role]);

	const forceUpdate = useCallback(() => {
		updateState({});
	}, []);

	const getNurseClassx = async (nomina: any): Promise<void> => {
		try {
			const response = await fetch(`${URI_API}/nurses`, getHeaders());
			const data = await response.json();
			const selectedNurse = data.find((nurse: any) => nurse.payroll === nomina);
			setNurseClassxSelected(selectedNurse);
		} catch (error) {
			console.error("Error fetching nurse class:", error);
		}
	};

	/**
	 * Fetches the nurse class based on the provided nomina.
	 * @param nomina The nomina of the nurse.
	 */
	const onSelectNurse = async (nomina: any): Promise<void> => {
		forceUpdate();
		try {
			const response = await fetch(
				`${URI_API}/nurse/schedule_info?` + new URLSearchParams({ nomina }),
				getHeaders(),
			);
			const data = await response.json();
			setSelectedNurse(data);

			const months: Date[][] = Array.from({ length: 12 }, () => []);
			const monthsOff: Date[][] = Array.from({ length: 12 }, () => []);

			data.Dias_Trabajo.forEach((fecha: string) => {
				const aux = new Date(fecha);
				months[aux.getMonth()].push(aux);
			});

			data.Dias_Descanso.forEach((fecha: string) => {
				const aux = new Date(fecha);
				monthsOff[aux.getMonth()].push(aux);
			});

			const setters = [
				setJanuaryMonth,
				setFebruaryMonth,
				setMarchMonth,
				setAprilMonth,
				setMayMonth,
				setJuneMonth,
				setJulyMonth,
				setAugustMonth,
				setSeptemberMonth,
				setOctoberMonth,
				setNovemberMonth,
				setDecemberMonth,
			];

			const settersOff = [
				setJanuaryMonthOff,
				setFebruaryMonthOff,
				setMarchMonthOff,
				setAprilMonthOff,
				setMayMonthOff,
				setJuneMonthOff,
				setJulyMonthOff,
				setAugustMonthOff,
				setSeptemberMonthOff,
				setOctoberMonthOff,
				setNovemberMonthOff,
				setDecemberMonthOff,
			];

			months.forEach((month, index) => setters[index](month));
			monthsOff.forEach((monthOff, index) => settersOff[index](monthOff));

			forceUpdate();
		} catch (error) {
			console.error("Error fetching nurse schedule info:", error);
		}
	};

	const handleNurseChange = (e: any, newValue: any) => {
		if (newValue) {
			setShowBackdrop(true);

			setNurseNomina(newValue.payroll);
			setNewClassxSelected(nurseClassxSelected.class);

			Promise.all([
				onSelectNurse(newValue.payroll),
				getNurseClassx(newValue.payroll),
			]).finally(() => setShowBackdrop(false));
		}
	};

	const [selectedDaysType, setSelectedDaysType] = useState<string>("laboral");
	const toggleDays = (event: React.MouseEvent<HTMLElement>, newValue: string): void => {
		if (newValue !== null) setSelectedDaysType(newValue);
	};

	const isLaboralDay = (): boolean => {
		return selectedDaysType === "laboral";
	};

	const months = [
		{
			monthIndex: 0,
			selectedDays: isLaboralDay() ? januaryMonth : januaryMonthOff,
			setSelectedDays: isLaboralDay() ? setJanuaryMonth : setJanuaryMonthOff,
			dayOffModifier: isLaboralDay() ? januaryMonthOff : januaryMonth,
		},
		{
			monthIndex: 1,
			selectedDays: isLaboralDay() ? februaryMonth : februaryMonthOff,
			setSelectedDays: isLaboralDay() ? setFebruaryMonth : setFebruaryMonthOff,
			dayOffModifier: isLaboralDay() ? februaryMonthOff : februaryMonth,
		},
		{
			monthIndex: 2,
			selectedDays: isLaboralDay() ? marchMonth : marchMonthOff,
			setSelectedDays: isLaboralDay() ? setMarchMonth : setMarchMonthOff,
			dayOffModifier: isLaboralDay() ? marchMonthOff : marchMonth,
		},
		{
			monthIndex: 3,
			selectedDays: isLaboralDay() ? aprilMonth : aprilMonthOff,
			setSelectedDays: isLaboralDay() ? setAprilMonth : setAprilMonthOff,
			dayOffModifier: isLaboralDay() ? aprilMonthOff : aprilMonth,
		},
		{
			monthIndex: 4,
			selectedDays: isLaboralDay() ? mayMonth : mayMonthOff,
			setSelectedDays: isLaboralDay() ? setMayMonth : setMayMonthOff,
			dayOffModifier: isLaboralDay() ? mayMonthOff : mayMonth,
		},
		{
			monthIndex: 5,
			selectedDays: isLaboralDay() ? juneMonth : juneMonthOff,
			setSelectedDays: isLaboralDay() ? setJuneMonth : setJuneMonthOff,
			dayOffModifier: isLaboralDay() ? juneMonthOff : juneMonth,
		},
		{
			monthIndex: 6,
			selectedDays: isLaboralDay() ? julyMonth : julyMonthOff,
			setSelectedDays: isLaboralDay() ? setJulyMonth : setJulyMonthOff,
			dayOffModifier: isLaboralDay() ? julyMonthOff : julyMonth,
		},
		{
			monthIndex: 7,
			selectedDays: isLaboralDay() ? augustMonth : augustMonthOff,
			setSelectedDays: isLaboralDay() ? setAugustMonth : setAugustMonthOff,
			dayOffModifier: isLaboralDay() ? augustMonthOff : augustMonth,
		},
		{
			monthIndex: 8,
			selectedDays: isLaboralDay() ? septemberMonth : septemberMonthOff,
			setSelectedDays: isLaboralDay() ? setSeptemberMonth : setSeptemberMonthOff,
			dayOffModifier: isLaboralDay() ? septemberMonthOff : septemberMonth,
		},
		{
			monthIndex: 9,
			selectedDays: isLaboralDay() ? octoberMonth : octoberMonthOff,
			setSelectedDays: isLaboralDay() ? setOctoberMonth : setOctoberMonthOff,
			dayOffModifier: isLaboralDay() ? octoberMonthOff : octoberMonth,
		},
		{
			monthIndex: 10,
			selectedDays: isLaboralDay() ? novemberMonth : novemberMonthOff,
			setSelectedDays: isLaboralDay() ? setNovemberMonth : setNovemberMonthOff,
			dayOffModifier: isLaboralDay() ? novemberMonthOff : novemberMonth,
		},
		{
			monthIndex: 11,
			selectedDays: isLaboralDay() ? decemberMonth : decemberMonthOff,
			setSelectedDays: isLaboralDay() ? setDecemberMonth : setDecemberMonthOff,
			dayOffModifier: isLaboralDay() ? decemberMonthOff : decemberMonth,
		},
	];

	return (
		<>
			<div>
				<div className="titleContainer">
					<div className="monthContainer">
						<div className="monthLabel">{actualYear}</div>
						<button
							className="monthButtons"
							onClick={() => setActualYear(actualYear - 1)}
							style={{ marginLeft: "2%" }}
						>
							<FaChevronLeft
								style={{
									alignSelf: "center",
								}}
							></FaChevronLeft>
						</button>
						<div className="dot"></div>
						<button
							className="monthButtons"
							onClick={() => setActualYear(actualYear + 1)}
							style={{ marginRight: "2%" }}
						>
							<FaChevronRight
								style={{
									alignSelf: "center",
								}}
							></FaChevronRight>
						</button>
					</div>
					<div className="sectorContainer">
						<button
							onClick={() => setShowModal(true)}
							className="plain-button plain-button--medium"
						>
							<ExcelIcon
								className="plain-button--icon"
								style={{
									marginRight: "0.5vw",
								}}
							></ExcelIcon>
							Subir Documento
						</button>
						<Autocomplete
							id="seleccionarprofesional"
							options={nursesByHospital.sort((a: any, b: any) =>
								a.name.localeCompare(b.name),
							)}
							getOptionLabel={(option: any) => option.name}
							onChange={handleNurseChange}
							size="small"
							renderInput={(params) => (
								<TextField
									{...params}
									label="Seleccionar Profesional"
									variant="outlined"
									className="custom-autocomplete"
									InputProps={{
										...params.InputProps,
										endAdornment: (
											<React.Fragment>
												{loadingAutocompleteSelectNurse ? (
													<CircularProgress
														color="inherit"
														size={20}
													/>
												) : null}
												{params.InputProps.endAdornment}
											</React.Fragment>
										),
									}}
								/>
							)}
							loading={loadingAutocompleteSelectNurse}
						/>
						<Autocomplete
							id="seleccionarclassx"
							options={ClassX.sort((a: any, b: any) => a.localeCompare(b))}
							getOptionLabel={(option: any) => option}
							onChange={(e, newValue) => {
								if (newValue != null) {
									setNewClassxSelected(newValue);
									setNurseClassxSelected({ class: newValue });
								} else {
									setNewClassxSelected("");
								}
							}}
							size="small"
							sx={{
								marginLeft: "2%",
								marginRight: "2%",
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Categoría"
									variant="outlined"
									className="custom-autocomplete"
								/>
							)}
							value={nurseClassxSelected != null ? nurseClassxSelected.class : ""}
						/>
						<button
							disabled={nurseNomina === ""}
							className="pill-button"
							style={{
								opacity: isUpdating ? 0.4 : 1,
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
							onClick={sendChanges}
						>
							<ActualizarIcon
								fill="white"
								style={{
									marginRight: "0.5vw",
								}}
							/>
							Actualizar
						</button>
					</div>
				</div>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<div className="labels-container">
						<ToggleButtonGroup
							disabled={!isAdmin}
							value={isAdmin ? selectedDaysType : ["laboral", "no-laboral"]}
							exclusive
							onChange={toggleDays}
							className="custom-togglebutton"
						>
							<ToggleButton
								className={`label-container ${
									isAdmin
										? selectedDaysType === "laboral"
											? "active-option"
											: "disabled-option"
										: "active-option"
								}`}
								value={"laboral"}
							>
								<div className="first-indicator" />
								Días laborales
							</ToggleButton>
							<ToggleButton
								className={`label-container ${
									isAdmin
										? selectedDaysType === "no-laboral"
											? "active-option--no-laboral"
											: "disabled-option"
										: "active-option--no-laboral"
								}`}
								value={"no-laboral"}
							>
								<div className="second-indicator" />
								Días no laborales
							</ToggleButton>
						</ToggleButtonGroup>
					</div>
					<a
						className="buttonActionOptions"
						href="https://drive.google.com/uc?export=download&id=1Zf06lH_n6NU_4K-bANjtLC26AfVrsdeJ"
					>
						Descargar plantilla automatizada
					</a>
				</Box>
				<div
					style={{
						overflow: "auto",
						scrollbarWidth: "thin",
						marginTop: "1.7vh",
					}}
				>
					{Array.from({ length: 3 }, (_, i) => (
						<Box
							key={i}
							style={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "space-around",
								width: "100%",
							}}
						>
							{months
								.slice(i * 4, i * 4 + 4)
								.map(
									({
										monthIndex,
										selectedDays,
										setSelectedDays,
										dayOffModifier,
									}) => (
										<MonthPicker
											key={monthIndex}
											monthIndex={monthIndex}
											selectedDays={selectedDays}
											setSelectedDays={setSelectedDays}
											isLaboralDay={isLaboralDay}
											isAdmin={isAdmin}
											dayOffModifier={dayOffModifier}
										/>
									),
								)}
						</Box>
					))}
					<Backdrop open={showBackdrop}>
						<CircularProgress style={{ color: " #62098c" }} />
					</Backdrop>
				</div>
				<Modal
					open={showModal}
					onClose={() => setShowModal(false)}
				>
					<Box className="modalBody">
						<UploadArchive
							updateNurses={getNurses}
							onClose={() => setShowModal(false)}
							onSuccess={() =>
								toast("Cargado exitoso", { type: "success", containerId: "PC" })
							}
						/>
					</Box>
				</Modal>
			</div>
			<ToastContainer
				containerId="PC"
				position="top-right"
				autoClose={3000}
				hideProgressBar={false}
				closeOnClick
				rtl={false}
				draggable={false}
				newestOnTop={true}
			></ToastContainer>
		</>
	);
}
