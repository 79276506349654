/*
    File created: 15/03/2024
    Authors:
        Erik Josías González Lucas

    Modal to assign a auxiliar nurse to a patient.
    This file was created to modulate and optimize code from patientForm.tsx
*/

import { NursePriority } from "../../utils/nursePriority";
import { ISelectedPatient } from "../../store/interfaces";
import { URI_API, getHeaders } from "../../utils/config";
import Shift from "../../utils/shiftFunctions";

import { AppState, deletePatientSecondaryNurse, setPatientSecondaryNurse } from "../../store/reducer";
import { Box, CircularProgress, Modal } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Dispatch } from "redux";

export default function ModalSelectAuxNurse(props: { open: boolean; onClose: Function; onSave: Function }) {
    const selectedPatient: ISelectedPatient = useSelector((state: AppState) => state.selectedPatient);

    const [nurses, setNurses] = useState<any>([]);
    const [filteredNurses, setFilteredNurses] = useState<any>([]);
    const [selectednurse_id, setSelectednurse_id] = useState<string>(selectedPatient.secondarynurse_id ?? "");
    const [selectedNurseName, setSelectedNurseName] = useState<string>("");
    const [searchInput, setSearchInput] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isNursesEmpty, setIsNursesEmpty] = useState<boolean>(false);

    const dispatch: Dispatch<any> = useDispatch();

    const fetchSchedule = async (date: any) => {
        const dateToSearch = date.toISOString().slice(0, 10);
        return await (await fetch(`${URI_API}/work_schedule_name_by_day/${dateToSearch}`, getHeaders())).json();
    } 

    const getNurseInformation = async () => {
        setIsLoading(true);
        const date = new Date();
        const pad = (num: any) => num.toString().padStart(2, '0');
        const year = date.getFullYear();
        const month = pad(date.getMonth() + 1); 
        const day = pad(date.getDate()); 

        const midnight = new Date(`${year}-${month}-${day}T00:00:00.000-06:00`);
        const default_departure_time = new Date(`${year}-${month}-${day}T07:30:00.000-06:00`);
        let currentShift, schedulesRes;
        // Night shift flow
        if (date > midnight && date < default_departure_time) {
            const yesterday = new Date(date.getTime() - 86400000);
            schedulesRes = await fetchSchedule(yesterday);
            if (schedulesRes[0].schedule_name.includes("nocturno")) {
                currentShift = Shift.AsOption(Shift.Current(schedulesRes));
            } else {
                schedulesRes = await fetchSchedule(date);
                currentShift = schedulesRes[0].schedule_name.includes("nocturno") 
                ? null
                : Shift.AsOption(Shift.Current(schedulesRes));
            }
        // Non-night shift flow
        } else {
            schedulesRes = await fetchSchedule(date);
            currentShift = Shift.AsOption(Shift.Current(schedulesRes));
        }

        // Get Classification Matrix
        let matrix_res = await (await fetch(`${URI_API}/class_category_matrix`, getHeaders())).json();

        // const today_departure_time = new Date(date.getFullYear() + '-' + ((date.getMonth()) + 1) + '-' + date.getDate() + 'T' + currentShift?.departure_time + ':00.000-06:00');
        const today_departure_time = new Date(`${year}-${month}-${day}T${currentShift?.departure_time}:00.000-06:00`);
        let date_str;
        if(date > midnight && date < today_departure_time && currentShift?.key.toLowerCase().includes("nocturno")) {
            const date_str_raw = new Date(date.getTime() - 86400000);
            date_str = date_str_raw.toISOString().slice(0, 10);
        } else {
            date_str = date.toISOString().slice(0, 10);
        }

        let nurses = await (
            await fetch(`${URI_API}/nursing_calendar_sch/${date_str}/${currentShift?.key}/${selectedPatient.area_id}`, getHeaders())
        ).json();

        let availableNurses = NursePriority(schedulesRes.length, selectedPatient, nurses, matrix_res);

        setNurses(availableNurses);
        setFilteredNurses(availableNurses);
        setIsNursesEmpty(availableNurses.length === 0);
        setIsLoading(false);
    };

    useEffect(() => {
        if (props.open && nurses.length === 0) getNurseInformation();
    }, [props.open]);

    useEffect(() => {
        if (searchInput === "") setFilteredNurses(nurses);
        else {
            setFilteredNurses(
                nurses.filter((nurse: any) => {
                    return nurse.nursename.toLowerCase().includes(searchInput) || nurse.classx.toLowerCase().includes(searchInput);
                })
            );
        }
    }, [searchInput]);

    const buildOptions = (): JSX.Element | JSX.Element[] => {
        if (filteredNurses === undefined) return <></>;

        return filteredNurses.map((nurse: any) => {
            if (nurse.classx == "AUX A" || nurse.classx == "AUX B" || nurse.classx == "AUX C" || nurse.classx == "AUX" || nurse.classx == "ENF AUX")
                return (
                    <Box className="custom-modal__body__table__row">
                        <Box className="custom-modal__body__table__cell--small">
                            <input
                                type="radio"
                                checked={nurse.nurse_id === selectednurse_id}
                                onClick={() => {
                                    setSelectednurse_id(nurse.nurse_id);
                                    setSelectedNurseName(nurse.nursename);
                                }}
                            ></input>
                        </Box>
                        <Box className="custom-modal__body__table__cell--big">
                            <h5>{nurse.nursename}</h5>
                        </Box>
                        <Box className="custom-modal__body__table__cell--medium">
                            <h5>{nurse.classx}</h5>
                        </Box>
                        <Box className="custom-modal__body__table__cell--big custom-modal__circles">
                            <Box className="custom-modal__circle custom-modal__circle--purple">
                                {nurse.patients.filter((patient: any) => patient.categoryid === "29404BCA-B6D6-4FD2-A3A8-5A3663CF4552").length}
                            </Box>
                            <Box className="custom-modal__circle custom-modal__circle--cyan">
                                {nurse.patients.filter((patient: any) => patient.categoryid === "B7547881-1699-4A24-B1E1-FDA3C5A8552D").length}
                            </Box>
                            <Box className="custom-modal__circle custom-modal__circle--green">
                                {nurse.patients.filter((patient: any) => patient.categoryid === "A3C112AB-1756-4EC3-B3B6-C241DFD88025").length}
                            </Box>
                            <Box className="custom-modal__circle custom-modal__circle--yellow">
                                {nurse.patients.filter((patient: any) => patient.categoryid === "976C5E6B-0FF5-4FF4-8573-00E342F18E6D").length}
                            </Box>
                            <Box className="custom-modal__circle custom-modal__circle--blue">
                                {nurse.patients.filter((patient: any) => patient.categoryid === "9C582510-34A2-4A77-A415-436C95AF6839").length}
                            </Box>
                            <Box className="custom-modal__circle custom-modal__circle--red">
                                {nurse.patients.filter((patient: any) => patient.categoryid === "BC0C92FD-B7EB-4777-B8E9-88981D129667").length}
                            </Box>
                            <h5>
                                {" "}
                                {nurse.patients.length} paciente
                                {nurse.patients.length > 1 ? "s" : ""}
                            </h5>
                        </Box>
                    </Box>
                );
        });
    };

    const _onSave = (): void => {
        dispatch(
            setPatientSecondaryNurse({
                secondarynurse_id: selectednurse_id,
                secondaryNurseName: selectedNurseName,
            })
        );

        setSearchInput("");
        props.onClose();
    };

    const _onCancel = (): void => {
        setSelectednurse_id("");
        setSelectedNurseName("");
        setSearchInput("");

        dispatch(deletePatientSecondaryNurse());
        props.onClose();
    };

    return (
        <>
            <Modal open={props.open} onClose={() => props.onClose()}>
                <Box className="custom-modal custom-modal--big">
                    <Box className="custom-modal__title">
                        <h4>Selección de Profesional Auxiliar</h4>
                    </Box>
                    <input
                        className="custom-input"
                        type="text"
                        name="search"
                        placeholder="Buscar"
                        onChange={(event) => setSearchInput(event.target.value.toLowerCase())}
                    ></input>
                    <Box className="custom-modal__body">
                        <Box className="custom-modal__body__table__header">
                            <Box style={{ width: "8%" }}></Box>
                            <h5 className="custom-modal__body__table__cell--big">Profesional</h5>
                            <h5 className="custom-modal__body__table__cell--medium">Categoría</h5>
                            <h5 className="custom-modal__body__table__cell--big">Pacientes</h5>
                        </Box>
                        <Box className="custom-modal__body__table">
                            {isLoading ? (
                                <Box className="custom-modal__center">
                                    <CircularProgress color="secondary" />
                                </Box>
                            ) : isNursesEmpty ? (
                                <Box className="custom-modal__center">No hay personal disponible. Revisa el ajuste de asistencia.</Box>
                            ) : (
                                buildOptions()
                            )}
                        </Box>
                        {!isLoading && (
                            <Box className="custom-modal__body__buttons">
                                <button className="custom-modal__body__buttons--cancel" onClick={_onCancel}>
                                    Cancelar
                                </button>
                                {!isNursesEmpty && (
                                    <button className="custom-modal__body__buttons--accept" onClick={_onSave}>
                                        Aceptar
                                    </button>
                                )}
                            </Box>
                        )}
                    </Box>
                </Box>
            </Modal>
        </>
    );
}
